<template>
    <v-app class="w-100 h-100">
        <Header></Header>
        <v-row>
            <v-col class="col-lg-2">
                <Menu></Menu>
            </v-col>
            <v-col class="col-lg-10">
                <v-card class="w-100 h-100">
                    <v-card-title>
                        Base de conocimiento
                        <span>
                            
                        </span>
                    </v-card-title>

                    <v-card-text>

                        <v-card>
                    <v-card-title>Documentos</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col lg-3>
                                <v-card
                                    class="mx-auto"
                                    width="350">
                                    <v-card-title class="old_rose-fondo white-texto pt-11">
                                        <v-icon class="mr-2 class white-texto">
                                            mdi-alert-decagram
                                        </v-icon>
                                        Nuevo
                                    </v-card-title>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <h3 class="mb-4">
                                                Manual Mesa de Ayuda 
                                            </h3>
                                        </v-list-item-content>
                                     <v-list-item-avatar>
                                        <v-icon
                                                    aria-hidden
                                                    rounded
                                                    large
                                                    class="maximum-red-texto text-center">
                                                    mdi-book
                                                </v-icon>
                                     </v-list-item-avatar>

                                    </v-list-item>
                                    <v-card-actions>
                                        <v-btn
                                            href="./documentos/Manual_Mesa_de_Ayuda_V4.pdf"
                                            target="_blank"
                                            block
                                            class="jet-fondo white-texto"
                                            >
                                            Ver archivo
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>

                            <v-col>
                                <v-card
                                    class="mx-auto"
                                    width="350">
                                    <v-card-title class="old_rose-fondo white-texto">
                                        <v-icon class="mr-2 class white-texto">
                                            mdi-alert-decagram
                                        </v-icon>
                                        Nuevo
                                    </v-card-title>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <h3 class="mb-4">
                                                Manual Registro Proveedores
                                            </h3>
                                        </v-list-item-content>
                                     <v-list-item-avatar>
                                        <v-icon
                                                    aria-hidden
                                                    rounded
                                                    large
                                                    class="maximum-red-texto text-center">
                                                    mdi-book
                                                </v-icon>
                                     </v-list-item-avatar>

                                    </v-list-item>
                                    <v-card-actions>
                                        <v-btn
                                            href="./documentos/Manual_Registro_Proveedores.pdf"
                                            target="_blank"
                                            block
                                            class="jet-fondo white-texto"
                                            >
                                            Ver archivo
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>

                            <v-col>
                                <v-card
                                    class="mx-auto"
                                    width="350">
                                    <v-card-title class="old_rose-fondo white-texto">
                                        <v-icon class="mr-2 class white-texto">
                                            mdi-alert-decagram
                                        </v-icon>
                                        Nuevo
                                    </v-card-title>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <h3 class="mb-4">
                                                Manual de Uso App Atika
                                            </h3>
                                        </v-list-item-content>
                                     <v-list-item-avatar>
                                        <v-icon
                                                    aria-hidden
                                                    rounded
                                                    large
                                                    class="maximum-red-texto text-center">
                                                    mdi-book
                                                </v-icon>
                                     </v-list-item-avatar>

                                    </v-list-item>
                                    <v-card-actions>
                                        <v-btn
                                            href="./documentos/Manual_App_Atika_V1.pdf"
                                            target="_blank"
                                            block
                                            class="jet-fondo white-texto"
                                            >
                                            Ver archivo
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>

                            <v-col>
                                <v-card
                                    class="mx-auto"
                                    width="350">
                                    <v-card-title class="old_rose-fondo white-texto">
                                        
                                    </v-card-title>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <h3 class="mb-4">
                                                Cascada de Emergencia ante Sismos y Terremotos
                                            </h3>
                                        </v-list-item-content>
                                     <v-list-item-avatar>
                                        <v-icon
                                                    aria-hidden
                                                    rounded
                                                    large
                                                    class="maximum-red-texto text-center">
                                                    mdi-book
                                                </v-icon>
                                     </v-list-item-avatar>

                                    </v-list-item>
                                    <v-card-actions>
                                        <v-btn
                                            href="./documentos/cascada_de_Emergencia_en_caso_de_sismos_y_terremotos.pdf"
                                            target="_blank"
                                            block
                                            class="jet-fondo white-texto"
                                            >
                                            Ver archivo
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                            <v-col>
                                <v-card
                                    class="mx-auto"
                                    width="350">
                                    <v-card-title class="old_rose-fondo white-texto">
                                    
                                    </v-card-title>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <h3 class="mb-4">
                                                Manual del Proceso de Venta en Odoo
                                            </h3>
                                        </v-list-item-content>
                                     <v-list-item-avatar>
                                        <v-icon
                                                    aria-hidden
                                                    rounded
                                                    large
                                                    class="maximum-red-texto text-center">
                                                    mdi-book
                                                </v-icon>
                                     </v-list-item-avatar>

                                    </v-list-item>
                                    <v-card-actions>
                                        <v-btn
                                            href="./documentos/Procedimiento_de_Ventas_Odoo_Atika_Final.pdf"
                                            target="_blank"
                                            block
                                            class="jet-fondo white-texto"
                                            >
                                            Ver archivo
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>

                            <v-col>
                                <v-card
                                    class="mx-auto"
                                    width="350">
                                    <v-card-title class="old_rose-fondo white-texto">
                                    </v-card-title>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <h3 class="mb-4">
                                                Procedimiento Informe Diario
                                            </h3>
                                        </v-list-item-content>
                                     <v-list-item-avatar>
                                        <v-icon
                                                    aria-hidden
                                                    rounded
                                                    large
                                                    class="maximum-red-texto text-center">
                                                    mdi-book
                                                </v-icon>
                                     </v-list-item-avatar>

                                    </v-list-item>
                                    <v-card-actions>
                                        <v-btn
                                            href="./documentos/Procedimiento_Informe_Diario.pdf"
                                            target="_blank"
                                            block
                                            class="jet-fondo white-texto"
                                            >
                                            Ver archivo
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>

                            <v-col>
                                <v-card
                                    class="mx-auto"
                                    width="350">
                                    <v-card-title class="old_rose-fondo white-texto">
                                    </v-card-title>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <h3 class="mb-4">
                                                Procedimiento en Rindegastos
                                            </h3>
                                        </v-list-item-content>
                                     <v-list-item-avatar>
                                        <v-icon
                                                    aria-hidden
                                                    rounded
                                                    large
                                                    class="maximum-red-texto text-center">
                                                    mdi-book
                                                </v-icon>
                                     </v-list-item-avatar>

                                    </v-list-item>
                                    <v-card-actions>
                                        <v-btn
                                            href="./documentos/Procedimiento_en_Rindegastos.pdf"
                                            target="_blank"
                                            block
                                            class="jet-fondo white-texto"
                                            >
                                            Ver archivo
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>

                            <v-col>
                                <v-card
                                    class="mx-auto"
                                    width="350">
                                    <v-card-title class="old_rose-fondo white-texto">
                                    </v-card-title>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <h3 class="mb-4">
                                                Anexo 01 Formato de Analsis de Cuentas
                                            </h3>
                                        </v-list-item-content>
                                     <v-list-item-avatar>
                                        <v-icon
                                                    aria-hidden
                                                    rounded
                                                    large
                                                    class="maximum-red-texto text-center">
                                                    mdi-book
                                                </v-icon>
                                     </v-list-item-avatar>

                                    </v-list-item>
                                    <v-card-actions>
                                        <v-btn
                                            href="./documentos/Anexo_01_Formato_de_Analsis_de_Cuentas.pdf"
                                            target="_blank"
                                            block
                                            class="jet-fondo white-texto"
                                            >
                                            Ver archivo
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>

                            <v-col>
                                <v-card
                                    class="mx-auto"
                                    width="350">
                                    <v-card-title class="old_rose-fondo white-texto">
                                    </v-card-title>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <h3 class="mb-4">
                                                Anexo 02 Articulos SAP Vigente
                                            </h3>
                                        </v-list-item-content>
                                     <v-list-item-avatar>
                                        <v-icon
                                                    aria-hidden
                                                    rounded
                                                    large
                                                    class="maximum-red-texto text-center">
                                                    mdi-book
                                                </v-icon>
                                     </v-list-item-avatar>

                                    </v-list-item>
                                    <v-card-actions>
                                        <v-btn
                                            href="./documentos/Anexo_02_Articulos_SAP_Vigente.pdf"
                                            target="_blank"
                                            block
                                            class="jet-fondo white-texto"
                                            >
                                            Ver archivo
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>

                            <v-col>
                                <v-card
                                    class="mx-auto"
                                    width="350">
                                    <v-card-title class="old_rose-fondo white-texto">
                                    </v-card-title>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <h3 class="mb-4">
                                                Anexo 03 Plan de Acción
                                            </h3>
                                        </v-list-item-content>
                                     <v-list-item-avatar>
                                        <v-icon
                                                    aria-hidden
                                                    rounded
                                                    large
                                                    class="maximum-red-texto text-center">
                                                    mdi-book
                                                </v-icon>
                                     </v-list-item-avatar>

                                    </v-list-item>
                                    <v-card-actions>
                                        <v-btn
                                            href="./documentos/Anexo_03_Plan_de_Acción.pdf"
                                            target="_blank"
                                            block
                                            class="jet-fondo white-texto"
                                            >
                                            Ver archivo
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>

                            <v-col>
                                <v-card
                                    class="mx-auto"
                                    width="350">
                                    <v-card-title class="old_rose-fondo white-texto">
                                    </v-card-title>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <h3 class="mb-4">
                                                Procedimiento Analisis de Cuentas y Castigos
                                            </h3>
                                        </v-list-item-content>
                                     <v-list-item-avatar>
                                        <v-icon
                                                    aria-hidden
                                                    rounded
                                                    large
                                                    class="maximum-red-texto text-center">
                                                    mdi-book
                                                </v-icon>
                                     </v-list-item-avatar>

                                    </v-list-item>
                                    <v-card-actions>
                                        <v-btn
                                            href="./documentos/Procedimiento_Analisis_de_Cuentas_y_Castigos.pdf"
                                            target="_blank"
                                            block
                                            class="jet-fondo white-texto"
                                            >
                                            Ver archivo
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>

                            <v-col>
                                <v-card
                                    class="mx-auto"
                                    width="350">
                                    <v-card-title class="old_rose-fondo white-texto">
                                    </v-card-title>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <h3 class="mb-4">
                                                Procedimiento de Flujo Rendición en Rindegastos
                                            </h3>
                                        </v-list-item-content>
                                     <v-list-item-avatar>
                                        <v-icon
                                                    aria-hidden
                                                    rounded
                                                    large
                                                    class="maximum-red-texto text-center">
                                                    mdi-book
                                                </v-icon>
                                     </v-list-item-avatar>

                                    </v-list-item>
                                    <v-card-actions>
                                        <v-btn
                                            href="./documentos/Procedimiento_de_Flujo_Rendición_en_Rindegastos.pdf"
                                            target="_blank"
                                            block
                                            class="jet-fondo white-texto"
                                            >
                                            Ver archivo
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>

                            <v-col>
                                <v-card
                                    class="mx-auto"
                                    width="350">
                                    <v-card-title class="old_rose-fondo white-texto">
                                    </v-card-title>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <h3 class="mb-4">
                                                Procedimiento Emisión Informe Semanal de Cobranza
                                            </h3>
                                        </v-list-item-content>
                                     <v-list-item-avatar>
                                        <v-icon
                                                    aria-hidden
                                                    rounded
                                                    large
                                                    class="maximum-red-texto text-center">
                                                    mdi-book
                                                </v-icon>
                                     </v-list-item-avatar>

                                    </v-list-item>
                                    <v-card-actions>
                                        <v-btn
                                            href="./documentos/Procedimiento_Emisión_Informe_Semanal_de_Cobranza.pdf"
                                            target="_blank"
                                            block
                                            class="jet-fondo white-texto"
                                            >
                                            Ver archivo
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>

                            <v-col>
                                <v-card
                                    class="mx-auto"
                                    width="350">
                                    <v-card-title class="old_rose-fondo white-texto">
                                    </v-card-title>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <h3 class="mb-4">
                                                Procedimiento Compra y Registro y Pago Factura Proveedores
                                            </h3>
                                        </v-list-item-content>
                                     <v-list-item-avatar>
                                        <v-icon
                                                    aria-hidden
                                                    rounded
                                                    large
                                                    class="maximum-red-texto text-center">
                                                    mdi-book
                                                </v-icon>
                                     </v-list-item-avatar>

                                    </v-list-item>
                                    <v-card-actions>
                                        <v-btn
                                            href="./documentos/Procedimiento_O_Compra_y_Registro_y_Pago_Factura_Proveedores.pdf"
                                            target="_blank"
                                            block
                                            class="jet-fondo white-texto"
                                            >
                                            Ver archivo
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                        
                    </v-card-text>
                </v-card>
                
                <!-- Videotutoriales-->

                        <v-card>
                            <v-card-title>
                                Videotutoriales
                            </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col v-for="video in dataVideos">
                                        <v-card width="300">
                                            <v-img
                                            round
                                                class="sombraImagen"
                                                height="250"
                                                elevation="10"
                                                :src="video.miniatura"
                                                gradient="to top right, rgba(79, 93, 117, 0.5), rgba(255, 255, 255, 0.5)"
                                                >
                                            </v-img>
                                            <v-card-title>
                                                {{ video.titulo }}
                                            </v-card-title>
                                            <v-card-actions>
                                                <v-btn
                                                :href="video.url"
                                                target="_blank" 
                                                    block 
                                                    class="maximum_red-fondo white-texto">
                                                    Ver video
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                </v-card>

                
            </v-col>
        </v-row>
    </v-app>
</template>

<style>
.sombraImagen {
    -webkit-box-shadow: 0px 7px 4px 0px rgba(0,0,0,0.4);
-moz-box-shadow: 0px 7px 4px 0px rgba(0,0,0,0.4);
box-shadow: 0px 7px 4px 0px rgba(0,0,0,0.4);
border-radius: 5%;
}
</style>

<script>
import Menu from '@/components/Menu.vue'
import Header from '@/components/Header.vue'

export default {
    name:'BaseConocimiento',

    components: {Menu,Header},

    data() {
        return {
            embeded:'',
            dialogVideo:false,
            dataVideos : [
                {
                    url:'https://grupoatika.sharepoint.com/:v:/r/sites/AppAtika/Documentos%20compartidos/VideoTutoriales/Videotutorial%20configuraci%C3%B3n%20de%20tel%C3%A9fono.mp4?csf=1&web=1&e=Rxrdlh',
                    titulo:'Aprende a configurar tu nuevo teléfono IP',
                    miniatura: './imagenes/videos/configurar_telefono.jpg'
                },
                {
                    url:'https://grupoatika.sharepoint.com/:v:/r/sites/AppAtika/Documentos%20compartidos/VideoTutoriales/Explicaci%C3%B3n%20uso%20de%20Sala%20en%20Outlook.mp4?csf=1&web=1&e=gIiZDh',
                    titulo:'Agenda una sala de reuniones en Outlook',
                    miniatura: './imagenes/videos/salas_outlook.jpg'
                }
            ]
        }
    }, 

    methods: {
        cargaEmbeded: function(embeded) {
            console.log("Carga")
            this.embeded=embeded
            this.dialogVideo=true

        }
    }
}

</script>