<template>
    <v-app id="atikaApp" class="h-100 w-100">
        <Header style="position: fixed; width: 100% ; z-index: 1000000000"></Header>
        <v-row>
            <v-container>
                <br>
                <br>
                <v-row>
                    <v-col>
                        <v-card
                            width="1200px"
                            class="pa-3"
                            elevation="3">
                            <v-card-title>
                                Variables definidas para el cálculo
                            </v-card-title>
                            <v-card-text>
                            <v-row>
                                <v-col v-for="(factor,index) in sabanaFactores">
                                    <v-text-field outlined :disabled=factor.habilitada :name=factor.valor v-model="factor.valor" :label="factor.nombre" :placeholder="factor.valor" :value="factor.valor"></v-text-field>
                                </v-col>
                            </v-row>
                            </v-card-text>
                            <v-card-actions>
                                    <v-btn v-on:click="actualizarFactores" class="maximum_red-fondo white-texto">Cambiar Factores y recalcular</v-btn>
                                    <v-btn v-on:click="volverHome" class="mandarin-fondo white-texto">Volver al Home</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
        <!--
        <v-row>
            <v-col>
                <v-card class="mx-auto" width="1200" elevation="50">
                    <v-card-title class="maximum_red-texto">
                            Valores usados para el cálculo
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col v-for="valor in valoresCabecera">
                                <v-list subheader two-line>
                                    <v-list-item-content>
                                        <v-list-item-title class="xanadu-texto"><strong>{{valor.nombre}}</strong></v-list-item-title>
                                        <v-list-item-subtitle>{{valor.data}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    -->


    </v-container>
    <br />

    <v-container v-show="muestraProblemaComex">
        <v-card>
            <v-card-title> <span class="maximum_red-texto">No es posible realizar cálculos de precios</span> </v-card-title>
            <v-card-text>
                <span class="old_rose-texto">
                    <strong>
                    Algunos factores que se necesitan ingresar para realizar cálculo de precios. Por favor contactar con el área de Comex.
                    </strong>
                </span>
            </v-card-text>
        </v-card>
    </v-container>

    <br />


    <v-container fluid>


        <v-tabs
            v-model="tabPrecios"
            color="red darken-4"
            centered
            >


                <v-tab
                    href="#pendientes">
                    <v-icon>
                            mdi-google-spreadsheet
                        </v-icon>
                    Pendientes
                </v-tab>
                <v-tab
                    href="#aprobadas">
                    <v-icon  >
                            mdi-thumb-up
                        </v-icon>
                    Aprobadas
                </v-tab>
                <v-tab
                    href="#rechazadas">
                    <v-icon >
                            mdi-thumb-down
                        </v-icon>
                    Rechazadas
                </v-tab>

                <v-tabs-items v-model="tabPrecios">
                    <v-tab-item
                        value="pendientes">
                            <br />
                            <Lista filtro="pendientes"></Lista>
                    </v-tab-item>
                    <v-tab-item
                        value="aprobadas">
                        <Lista filtro="aprobadas"></Lista>
                    </v-tab-item>
                    <v-tab-item
                        value="rechazadas">
                        <Lista filtro="rechazadas"></Lista>
                    </v-tab-item>
                </v-tabs-items>


        </v-tabs>
            <v-snackbar
                    v-model="snackPrecio">
                    {{snackTexto}}

                </v-snackbar>
            </v-container>
        </v-row>
        
    </v-app>
</template>

<style>
.botonPanel {
	margin-right: 10px;
}
</style>

<script>
import Header from '@/components/Header.vue'
import axios from 'axios'
import HeaderPrecios from '@/components/globales/headers/HeaderPrecio.vue'
import Lista from '@/components/precio/Lista.vue'
import Menu from '@/components/Menu.vue'

export default {
    name:'Precios',

    components:{ HeaderPrecios,Lista,Menu, Header},

    data () {
        return {
            sabanaFactores:'',
            factoresSinDatos:[],
            nuevoFactor:{},
            muestraProblemaComex:false,
            id_producto_lista:0,
            snackPrecio:false,
            snackTexto:'',
            cargador:false,
            tabPrecios:'pendientes'
        }
    },

    created() {
        this.obtenDatosCabecera();
        this.obtieneFactores();
    },

    methods: {

        obtenDatosCabecera() {
            axios.post(process.env.VUE_APP_RUTA_COMEX,{
                accion:'valoresCabecera'
            }).then(response => {
                console.log("Valores cabecera")
                console.log(response.data)
                this.valoresCabecera=response.data
                this.revisarValoresCabecera()
            })
        },

        obtieneFactores() {
            axios.post(process.env.VUE_APP_RUTA_COMEX,{
                accion:'consultaFactores'
            }).then(response => {
                this.sabanaFactores=response.data
            })
        },

       

        actualizarFactores() {
            console.log("actualizar factores")
            console.log(this.sabanaFactores)
            axios.post(
                process.env.VUE_APP_RUTA_COMEX,{
                    accion:'actualizarFactores',
                    dataFactores:this.sabanaFactores
                }).then(response => {
                    this.snackTexto="Valores actualizados"
                    this.snackPrecio=true
                    this.calculaPrecios()
                })
        },

        

        revisarValoresCabecera() {
            let flagCabecera=0
            this.valoresCabecera.forEach(function(cabecera) {
                if (cabecera.data.length ==0) {
                    this.factoresSinDatos.push(cabecera.data)
                    flagCabecera++
                }
            })

            if (flagCabecera>0) {
                this.muestraProblemaComex=true
            }
        },

        volverHome() {
            this.$router.push('/Home')
        }

        
    }
}

</script>