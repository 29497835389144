<template>
    <v-app id="atikaApp" class="h-100 w-100">
      <Header style="position: fixed; width: 100%"></Header>
      <container fluid grid-list-sm>
        <v-layout row wrap>
          <!--<v-flex xs0 sm2 md2 xxl2>
            <Menu></Menu>
          </v-flex>-->
          <v-flex> <!--xs12 sm10 md10 xxl10-->
            <br>
            <br>
            <br>
            <br>
            <iframe style="overflow:auto;height:100vw;width:99vw;min-height: 1000px !important;" :src=marco
              scrolling="no" frameborder="0"> <!---->
            </iframe>
          </v-flex>
        </v-layout>
      </container>
    </v-app>
  </template>
  
  <script>
  import Menu from '@/components/Menu.vue'
  import Header from '@/components/Header.vue'
  export default {
    name: 'AnalisisCompra',
  
    components: { Menu,Header },
  
    data() {
      return {
        ruta: new URL(window.location.href),
        ref: '',
        marco: 'https://app.atika.cl/app-atika-registro-proveedores',
        idUsuario:this.$cookies.get("idUsuario")
      }
    },
  
    created() {
      this.marco = this.marco + '?idUsuario=' + this.idUsuario
      console.log("ruta menu -> " + this.marco)
    }
  }
  </script>