<template>
    <v-app id="atikaApp" class="h-100 w-100">
        <Header style="position: fixed; width: 100% ; z-index: 1000000000"></Header>
        <v-row>
            <v-container>
                <br>
                <br>
                <br>
                <br>
                <formularioComex></formularioComex>
            </v-container>
        </v-row>
    </v-app>
</template>

<script>
import Header from '@/components/Header.vue'
import Menu from '@/components/Menu.vue'
import formularioComex from '@/components/formularioComex.vue'

export default {
    components: {formularioComex,Header,Menu}
}


</script>