<template>
    <v-app>
        <br/>
        <br/>
        <v-container grid-list-md>
            <v-layout row wrap>
                <v-flex xs12 sm12 md3 xxl3 >  
                    <Fecha></Fecha>
                </v-flex>
                <v-flex xs12 sm12 md9 xxl9> 
                    <Indicadores></Indicadores>
                </v-flex>
            </v-layout>
        </v-container>
        <br>
        <br>
        <br>
        <br>
        <v-container grid-list-xl>
            <v-layout row wrap>
                <v-flex xs12 sm12 md6 xxl6 >  
                    <v-card color="#ededed" dark elevation="8">  <!--color="#EAF4F4" #C7D3DD -->
                        <v-card-title>
                            <h3 class="space-cadet-texto">Documentos</h3>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <v-img src = "/imagenes/DocumentsImage.png" alt="Logo Documentos" width="200px"></v-img>
                                </v-col>
                                <v-col>
                                    <h4 class="space-cadet-texto">Base de conocimiento asociada a los flujos de trabajo de la empresa..</h4>
                                    <v-row style="margin-top: 80px;">
                                        <v-btn @click="verDocumentos">
                                            ver mas
                                        </v-btn>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm12 md6 xxl6 >  
                    <v-card color="#ededed" dark elevation="8">
                        <v-card-title>
                            <h3 class="space-cadet-texto">Videotutoriales</h3>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <v-img src = "/imagenes/VideoTutorialImage.png" alt="Logo Documentos" width="200px"></v-img>
                                </v-col>
                                <v-col>
                                    <h4 class="space-cadet-texto">Videotutoriales demostrativos realizados con la finalidad de instruir al usuario a realizar correctamente los procesos.</h4>
                                    <v-row style="margin-top: 60px;">
                                        <v-btn  @click="verVideoTutoriales">
                                            ver mas
                                        </v-btn>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
        <v-dialog v-model="dialogDocumentos" persistent >
            <Documentos @cerrarD="cerrarDocumentos"></Documentos>
        </v-dialog>
        <v-dialog v-model="dialogVidetutoriales" persistent>
            <Videotutoriales @cerrarV="cerrarVideoTutoriales"></Videotutoriales>
        </v-dialog>
    </v-app>
</template>
<script>
import Fecha from '@/components/dashboard/Fecha.vue'
import Indicadores from '@/components/dashboard/Indicadores.vue'
import Documentos from '@/components/BasesConocimiento/Documentos.vue'
import Videotutoriales from '@/components/BasesConocimiento/Videotutoriales.vue'

export default {
    name:'Dashboard',
    components:{Fecha,Indicadores,Documentos,Videotutoriales},
    data() 
    {
        return {
            dialogVidetutoriales:false,
            dialogDocumentos:false,
            iframe:null,
            items: [
                {
                color: '#1F7087',
                src: 'https://cdn.vuetifyjs.com/images/cards/foster.jpg',
                title: 'Supermodel',
                artist: 'Foster the People',
                },
                {
                color: '#952175',
                src: 'https://cdn.vuetifyjs.com/images/cards/halcyon.png',
                title: 'Halcyon Days',
                artist: 'Ellie Goulding',
                },
            ],
        }
    }, 
    methods: {
        onLoad(frame) {
            this.iframe=frame.contentWindow
        },
        verDocumentos(){
            this.dialogDocumentos=true;
        },
        verVideoTutoriales(){
            this.dialogVidetutoriales=true;
        },
        cerrarDocumentos(value){
            this.dialogDocumentos=false;
        },
        cerrarVideoTutoriales(value){
            this.dialogVidetutoriales=false;
        }
    }   
}
</script>