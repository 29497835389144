<template>
  <v-app id="atikaApp" class="h-100 w-100">
    <Header></Header>
    <v-row>
      <v-col>
        <Dashboard></Dashboard>
      </v-col>
      <!--<v-col class="col-lg-2 col-md-2 col-sm-0">
        <Menu></Menu>
      </v-col>
      <v-col class="col-lg-10 col-md-2 col-sm-12">-->
        
      <!--</v-col>-->
    </v-row>
  </v-app>

</template>

<style>
.separacionSuperior {
  margin-top:35px;
}
</style>

<script>
// @ is an alias to /src

import axios from "axios";
import Menu from '@/components/Menu.vue'
import Dashboard from '@/components/Dashboard.vue'
import Header from '@/components/Header.vue'
export default {
  name: 'Home',
  
  components:{Menu,Dashboard,Header},

  data() {
    return {
     
      snackHome:false,
      snackTexto:'',
      nombreUsuario:'',
    }
  },


  created() {
    this.cargaParametros()
  },

  methods: {

    cargaParametros() {
      axios.post(process.env.VUE_APP_RUTA_COMEX,
      {accion:'actualizaParametros'}).then(response => {
      })
    }
    
  }
}
</script>
