<template>
    <v-row>
        <v-app-bar v-model="appBar">
                <v-toolbar-title>
                    <router-link to="/home" >
                        <span>
                            <v-img 
                                class="imagen_fondo"
                                src="@/assets/logo.svg"
                            ></v-img>
                        </span>
                    </router-link>
                </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">Administrativo</span>
                </template>
                <v-list>
                    <v-list-item
                        v-for="app in dataAdministrativo" v-bind:key="app.id_app" 
                        :to=app.enlace link>
                        <v-list-item-icon>
                            <v-icon>{{app.icono}}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>           
                            {{app.nombreApp}}
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-spacer></v-spacer>
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">Gerencial</span>
                </template>
                <v-list>
                    <v-list-item
                        v-for="app in dataGerencial" v-bind:key="app.id_app" 
                        :to=app.enlace link>
                        <v-list-item-icon>
                            <v-icon>{{app.icono}}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>           
                            {{app.nombreApp}}
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-spacer></v-spacer>
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">Producto</span>
                </template>
                <v-list>
                    <v-list-item
                        v-for="app in dataProducto" v-bind:key="app.id_app" 
                        :to=app.enlace link>
                    <v-list-item-icon>
                        <v-icon>{{app.icono}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>           
                        {{app.nombreApp}}
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            </v-menu>
            <v-spacer></v-spacer>
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">Comercial</span>
                </template>
                <v-list>
                    <v-list-item
                        v-for="app in dataComercial" v-bind:key="app.id_app" 
                        :to=app.enlace link>
                        <v-list-item-icon>
                            <v-icon>{{app.icono}}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>           
                            {{app.nombreApp}}
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-spacer></v-spacer>
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">Logistica</span>
                </template>
                <v-list>
                    <v-list-item
                        v-for="app in dataLogistica" v-bind:key="app.id_app" 
                        :to=app.enlace link>
                        <v-list-item-icon>
                            <v-icon>{{app.icono}}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>           
                            {{app.nombreApp}}
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-spacer></v-spacer>
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">Comex</span>
                </template>
                <v-list>
                    <v-list-item
                        v-for="app in dataComex" v-bind:key="app.id_app" 
                        :to=app.enlace link>
                        <v-list-item-icon>
                            <v-icon>{{app.icono}}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>           
                            {{app.nombreApp}}
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-spacer></v-spacer>
            <v-toolbar-title style="margin-right: 20px"><h5>{{nombreUsuario}}</h5></v-toolbar-title>
            <v-toolbar-title>
                <v-btn 
                    v-on:click="cerrarSesion"
                    class="maximum_red-fondo white-texto" block>
                Cerrar sesión
                </v-btn>
            </v-toolbar-title>
        </v-app-bar>
    </v-row>
</template>
<style>
.cabecera {
    height:50px !important;
    margin-top:15px !important;
}

.imagen_fondo {
    height:30px;
    width:120px;
    margin-left:15px;
    margin-bottom:15px;
}

.texto_derecha {
    margin-left:35%;
}
</style>
<script>
import axios from 'axios'
export default {
    name:'Header',

    data() {
        return {
            showMenu: false,
            appBar: true,
            grupos:['Administrativo','Gerencial','Producto','Comercial','Logistica'],
            dataAppsGrupos:[],
            dataAdministrativo:[],
            dataGerencial:[],
            dataProducto:[],
            dataComercial:[],
            dataLogistica:[],
            dataComex:[],
            nombreUsuario:"",
            dataApps:'',
            ruta:'',
            idUsuario:this.$cookies.get("idUsuario")
        }
    },

    created() {
        console.log("menu")
        this.autentificaUsuario()
            if(!(this.$route.name=='Login' && $this.$route.name=='/'))
        {
        this.navigationDrawer=true
        }
        this.ruta= this.$route.name;
        //this.obtieneAppsGrupos();
    },

    methods: {
        //Primero que todo, validar que el usuario exista
     autentificaUsuario:function() {
      if (!(this.$cookies.isKey("nombreUsuario"))) {
          this.$router.push("/");
        } else {
          if (this.$cookies.isKey("nombreUsuario"))
            {
              this.nombreUsuario=this.$cookies.get("nombreUsuario")
            } else  {
              this.nombreUsuario=this.usuarioLogin
            }
          this.navigationDrawer=true
          this.obtieneApps();
        }
    }, 

        cerrarSesion:function() {
        this.appBar=false
        this.navigationDrawer=false
        this.$cookies.remove("nombreUsuario")
        this.$cookies.remove("idUsuario")
        this.$router.push("/")
        },

        normalizaEnlaces:function(sabanaApp)
        {
          console.log("normalizaEnlaces")
          console.log(sabanaApp)
          console.log("Valor antes de foreach -> " + this.idUsuario)
          sabanaApp.forEach( app  =>  {
            if (app.iframe==1)
              {
                //console.log("Valor -> " + this.idUsuario)
                let enlace= app.id_app + "?ref=" + app.enlace + "?idUsuario=" + this.idUsuario
                //console.log("Enlace -> " + enlace)
                app.enlace=enlace
              }
          })
          return sabanaApp
        },

        /*obtieneApps:function() {
          var ruta=process.env.VUE_APP_API_USUARIOS_OBTENERAPLICACIONES + "/"  + this.idUsuario
          console.log("Ruta -> " + ruta)
          axios.get(ruta).then(response => {
            //this.dataApps=response.data;
            this.dataApps=this.normalizaEnlaces(response.data)
            console.log("dataApps")
            console.log(this.dataApps)
          })
        },*/

        obtieneApps(){
            var ruta = process.env.VUE_APP_API_USUARIOS_OBTENER_APLICACIONES;
            console.log(ruta);
            let id_usuario = this.idUsuario;
            let datos = {
                id_usuario
            }
            axios.post(ruta,datos).then(response=>{
                this.dataApps=response.data;
                console.log(this.dataApps);
                this.asignarAppsGrupo(this.dataApps);
            })
        },

        obtieneAppsGrupos:function() {
            
            this.dataAppsGrupos = [];
            var ruta='https://api.atika.cl/obtener/apps/grupos';

            axios.post(ruta).then(response => {
                this.dataAppsGrupos=(response.data)
                console.log(this.dataAppsGrupos);
                this.dataAdministrativo = this.normalizaEnlaces(this.dataAppsGrupos[0]);
                this.dataGerencial = this.normalizaEnlaces(this.dataAppsGrupos[1]);
                this.dataProducto = this.normalizaEnlaces(this.dataAppsGrupos[2]);
                this.dataComercial = this.normalizaEnlaces(this.dataAppsGrupos[3]);
                this.dataLogistica = this.normalizaEnlaces(this.dataAppsGrupos[4]);
            })
        },
        asignarAppsGrupo(data){

            for(let i = 0; data.length > i ; i++){
                if(data[i].nombreGrupo == 'Administrativo'){
                    this.dataAdministrativo.push(data[i])
                }else if(data[i].nombreGrupo == 'Gerencial'){
                    this.dataGerencial.push(data[i])
                }else if(data[i].nombreGrupo == 'Producto'){
                    this.dataProducto.push(data[i])
                }else if(data[i].nombreGrupo == 'Comercial'){
                    this.dataComercial.push(data[i])
                }else if(data[i].nombreGrupo == 'Logistica'){
                    this.dataLogistica.push(data[i])
                }else if(data[i].nombreGrupo == 'Comex'){
                    this.dataComex.push(data[i])
                }
            }
            
            this.validarGrupos(this.dataAdministrativo);
            this.validarGrupos(this.dataGerencial);
            this.validarGrupos(this.dataProducto);
            this.validarGrupos(this.dataComercial);
            this.validarGrupos(this.dataLogistica);
            this.validarGrupos(this.dataComex);

            console.log(this.dataAdministrativo);
            console.log(this.dataGerencial);
            console.log(this.dataProducto);
            console.log(this.dataComercial);
            console.log(this.dataLogistica);
            console.log(this.dataComex);
        },
        validarGrupos(datos){

            let sinAplicaciones = {
                id_app:0,
                nombreApp:'Sin aplicaciones',
                icono:'mdi-creation',
                enlace:'#'
            }
            if(datos.length == 0){
                datos.push(sinAplicaciones);
            }else{
                this.normalizaEnlaces(datos);
            }

            return datos;
        }   
    }
}
</script>