<template>
    <v-card color="#dcdcdc">
        <v-card-title>
        </v-card-title>
        <v-card-text>
            <h2 class="text-center">Documentos Atika</h2>
            <br>
            <v-row>
                <v-col >
                    <v-card class="documento" color="#6d6d6d" elevation="8">
                        <v-card-text>
                            <v-row>
                                <v-col md=3 xxl=3>
                                    <v-img src="/imagenes/pdf_logo.png" width="40px"></v-img>
                                </v-col>
                                <v-col md=8 xxl=8>
                                    <span class="white-texto">Manual Mesa de Ayuda</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-btn
                                href="./documentos/Manual_Mesa_de_Ayuda_V4.pdf"
                                target="_blank"
                                block>
                                    <h5>ver documento</h5>
                                </v-btn>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card class="documento" color="#6d6d6d" elevation="8">
                        <v-card-text>
                            <v-row>
                                <v-col md=3 xxl=3>
                                    <v-img src="/imagenes/pdf_logo.png" width="40px"></v-img>
                                </v-col>
                                <v-col>
                                    <span class="white-texto">Manual Registro Proveedores</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-btn
                                href="./documentos/Manual_Registro_Proveedores.pdf"
                                target="_blank"
                                block>
                                    <h5>ver documento</h5>
                                </v-btn>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card class="documento" color="#6d6d6d" elevation="8">
                        <v-card-text>
                            <v-row>
                                <v-col md=3 xxl=3>
                                    <v-img src="/imagenes/pdf_logo.png" width="40px"></v-img>
                                </v-col>
                                <v-col>
                                    <span class="white-texto">Manual de Uso App Atika</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-btn
                                href="./documentos/Manual_App_Atika_V2.pdf"
                                target="_blank"
                                block>
                                    <h5>ver documento</h5>
                                </v-btn>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card class="documento" color="#6d6d6d" elevation="8">
                        <v-card-text>
                            <v-row>
                                <v-col md=3 xxl=3>
                                    <v-img src="/imagenes/pdf_logo.png" width="40px"></v-img>
                                </v-col>
                                <v-col>
                                    <span class="white-texto">Cascada de Emergencia ante Sismos y Terremotos</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-btn 
                                href="./documentos/cascada_de_Emergencia_en_caso_de_sismos_y_terremotos.pdf"
                                target="_blank"
                                block>
                                    <h5>ver documento</h5>
                                </v-btn>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-card class="documento" color="#6d6d6d" elevation="8">
                        <v-card-text>
                            <v-row>
                                <v-col md=3 xxl=3>
                                    <v-img src="/imagenes/pdf_logo.png" width="40px"></v-img>
                                </v-col>
                                <v-col>
                                    <span class="white-texto">Manual del Proceso de Venta en Odoo</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-btn 
                                href="./documentos/Procedimiento_de_Ventas_Odoo_Atika_Final.pdf"
                                target="_blank"
                                block>
                                    <h5>ver documento</h5>
                                </v-btn>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card class="documento" color="#6d6d6d" elevation="8">
                        <v-card-text>
                            <v-row>
                                <v-col md=3 xxl=3>
                                    <v-img src="/imagenes/pdf_logo.png" width="40px"></v-img>
                                </v-col>
                                <v-col xs12 sm12 md8 xxl8>
                                    <span class="white-texto">Procedimiento Informe Diario</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-btn
                                href="./documentos/Procedimiento_Informe_Diario.pdf"
                                target="_blank"
                                block>
                                    <h5>ver documento</h5>
                                </v-btn>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card class="documento" color="#6d6d6d" elevation="8">
                        <v-card-text>
                            <v-row>
                                <v-col md=3 xxl=3>
                                    <v-img src="/imagenes/pdf_logo.png" width="40px"></v-img>
                                </v-col>
                                <v-col>
                                    <span class="white-texto">Procedimiento en Rindegastos</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-btn 
                                href="./documentos/Procedimiento_en_Rindegastos.pdf"
                                target="_blank"
                                block>
                                    <h5>ver documento</h5>
                                </v-btn>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card class="documento" color="#6d6d6d" elevation="8">
                        <v-card-text>
                            <v-row>
                                <v-col md=3 xxl=3>
                                    <v-img src="/imagenes/pdf_logo.png" width="40px"></v-img>
                                </v-col>
                                <v-col>
                                    <span class="white-texto">Anexo 01 Formato de Analsis de Cuentas</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-btn
                                href="./documentos/Anexo_01_Formato_de_Analsis_de_Cuentas.pdf"
                                target="_blank"
                                block>
                                    <h5>ver documento</h5>
                                </v-btn>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-card class="documento" color="#6d6d6d" elevation="8">
                        <v-card-text>
                            <v-row>
                                <v-col md=3 xxl=3>
                                    <v-img src="/imagenes/pdf_logo.png" width="40px"></v-img>
                                </v-col>
                                <v-col>
                                    <span class="white-texto">Anexo 02 Articulos SAP Vigente</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-btn
                                href="./documentos/Anexo_02_Articulos_SAP_Vigente.pdf"
                                target="_blank"
                                block>
                                    <h5>ver documento</h5>
                                </v-btn>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card class="documento" color="#6d6d6d" elevation="8">
                        <v-card-text>
                            <v-row>
                                <v-col md=3 xxl=3>
                                    <v-img src="/imagenes/pdf_logo.png" width="40px"></v-img>
                                </v-col>
                                <v-col>
                                    <span class="white-texto">Anexo 03 Plan de Acción</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-btn 
                                href="./documentos/Anexo_03_Plan_de_Acción.pdf"
                                target="_blank"
                                block>
                                    <h5>ver documento</h5>
                                </v-btn>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card class="documento" color="#6d6d6d" elevation="8">
                        <v-card-text>
                            <v-row>
                                <v-col md=3 xxl=3>
                                    <v-img src="/imagenes/pdf_logo.png" width="40px"></v-img>
                                </v-col>
                                <v-col xs12 sm12 md8 xxl8>
                                    <span class="white-texto">Procedimiento Analisis de Cuentas y Castigos</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-btn 
                                href="./documentos/Procedimiento_Analisis_de_Cuentas_y_Castigos.pdf"
                                target="_blank"
                                block>
                                    <h5>ver documento</h5>
                                </v-btn>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card class="documento" color="#6d6d6d" elevation="8">
                        <v-card-text>
                            <v-row>
                                <v-col md=3 xxl=3>
                                    <v-img src="/imagenes/pdf_logo.png" width="40px"></v-img>
                                </v-col>
                                <v-col>
                                    <span class="white-texto">Procedimiento Flujo Rendición en Rindegastos</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-btn 
                                href="./documentos/Procedimiento_de_Flujo_Rendición_en_Rindegastos.pdf"
                                target="_blank"
                                block>
                                    <h5>ver documento</h5>
                                </v-btn>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-card class="documento" color="#6d6d6d" elevation="8">
                        <v-card-text>
                            <v-row>
                                <v-col md=3 xxl=3>
                                    <v-img src="/imagenes/pdf_logo.png" width="40px"></v-img>
                                </v-col>
                                <v-col>
                                    <span class="white-texto">Procedimiento Emisión Informe Semanal de Cobranza</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-btn 
                                href="./documentos/Procedimiento_Emisión_Informe_Semanal_de_Cobranza.pdf"
                                target="_blank"
                                block>
                                    <h5>ver documento</h5>
                                </v-btn>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card class="documento" color="#6d6d6d" elevation="8">
                        <v-card-text>
                            <v-row>
                                <v-col md=3 xxl=3>
                                    <v-img src="/imagenes/pdf_logo.png" width="40px"></v-img>
                                </v-col>
                                <v-col>
                                    <span class="white-texto">Procedimiento Compra, Registro y Pago Factura Proveedores</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-btn 
                                href="./documentos/Procedimiento_O_Compra_y_Registro_y_Pago_Factura_Proveedores.pdf"
                                target="_blank"
                                block>
                                    <h5>ver documento</h5>
                                </v-btn>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col></v-col>
                <v-col></v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-row style="justify-content: flex-end; margin-right: 2%; margin-bottom: 0.5%;">
                <v-btn color="#000000" class="white-texto"@click="cerrar"><!--class="maximum_red-fondo white-texto"-->
                    cerrar
                </v-btn>
            </v-row>
        </v-card-actions>
    </v-card>
</template>
<style>
/*Registro de colores:

- #3d3b3f  --Gris semi oscuro
- rgba(31, 41, 55, 1) --Azul Marino


*/ 
.documento:hover {
    scale:1.1;
    transition: 0.2s;
}

</style>
<script>
import Fecha from '@/components/dashboard/Fecha.vue'
import Indicadores from '@/components/dashboard/Indicadores.vue'

export default {
    name:'Documentos',
    components:{Fecha,Indicadores},
    data() 
    {
        return {
        }
    }, 
    methods: {
        cerrar(){
            console.log('cerrando')
            this.$emit('cerrarD','1');
        }
    }   
}
</script>