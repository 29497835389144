<template>
    <div>
        <div v-if="mostrarIndicadores">
            <v-card 
                class="widget"
                elevation="10">
                <v-card-title>
                    <v-icon  
                        class="iconoSuperior"
                        medium>
                        mdi-chart-line
                    </v-icon>
                    <span class="texto-titulo-widget">Indicadores del día</span>
                </v-card-title>
                <v-card-text>
                    <v-row no-gutters v-if="mostrarIndicadores">
                        <v-col class="col-lg-2">
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>UF</v-list-item-title>
                                    <v-list-item-subtitle>$ {{Indicadores.uf.valor.toLocaleString('cl',{minimumFractionDigits:1,maximumFractionDigits:2})}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col class="col-lg-2">
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>UTM</v-list-item-title>
                                    <v-list-item-subtitle>$ {{Indicadores.utm.valor.toLocaleString('cl',{minimumFractionDigits:1,maximumFractionDigits:2})}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item> 
                        </v-col>
                        <v-col class="col-lg-2">
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>IPC</v-list-item-title>
                                    <v-list-item-subtitle>{{Indicadores.ipc.valor}} %</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item> 
                        </v-col>
                        <v-col class="col-lg-2">
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>Imacec</v-list-item-title>
                                    <v-list-item-subtitle>{{Indicadores.imacec.valor}} %</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item> 
                        </v-col>
                        
                            <v-col>
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>Dólar observado</v-list-item-title>
                                        <v-list-item-subtitle>$ {{Indicadores.dolar.valor.toLocaleString('cl',{minimumFractionDigits:1,maximumFractionDigits:2})}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item> 
                            </v-col>
                            <v-col>
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>Euro</v-list-item-title>
                                        <v-list-item-subtitle>$ {{Indicadores.euro.valor.toLocaleString('cl',{minimumFractionDigits:1,maximumFractionDigits:2})}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item> 
                            </v-col>
                        
                    </v-row>
                </v-card-text>
            </v-card>
        </div>
        <div v-if="!mostrarIndicadores">
            <v-card 
                class="widget"
                elevation="10">
                <v-card-title>
                    <v-icon  
                        class="iconoSuperior"
                        medium>
                        mdi-chart-line
                    </v-icon>
                    <span class="texto-titulo-widget">Indicadores del día</span>
                </v-card-title>
                <v-card-text>
                    <v-row >
                        <v-col>
                            <div>
                                <div class="loader text-center"style="margin-left: 45%;">
                                    <div class="loader__bar"></div>
                                    <div class="loader__bar"></div>
                                    <div class="loader__bar"></div>
                                    <div class="loader__bar"></div>
                                    <div class="loader__bar"></div>
                                    <div class="loader__ball"></div>
                                </div>
                            </div>
                            <h4 style="text-align: center;">Cargando indices de valor</h4>
                        </v-col>
                    </v-row> 
                </v-card-text>
            </v-card>
        </div>
    </div>
</template> 

<script>
import axios from 'axios'
export default {
    name:'Indicadores',
    
    data() {
        return{
            mostrarIndicadores:false,
            Indicadores:[],
            uf:''
        }
    },

    created() {
        this.obtenerIndicadores()
    },

    methods: {
        obtenerIndicadores: function() {
            axios.get("https://mindicador.cl/api").then(response => 
            {
                this.Indicadores=response.data;
                this.uf = this.Indicadores.uf.valor;
                this.mostrarIndicadores=true;
                //console.log(this.Indicadores.utm);

            }) 
        }
    }
}
</script>