<template>
    <v-app id="atikaApp" class="h-100 w-100">
      <Header style="position: fixed; width: 100%; z-index: 1000000000" ></Header>
        <div class="contenido">
            <v-layout row wrap>
                <v-flex> <!--xs12 sm10 md10 xxl10-->
                    <br>
                    <br>
                    <v-card>
                        <v-card-title>
                            Administración App Atika
                        </v-card-title>
                        <v-card-text>

                            <v-container fluid class="white-fondo">
                                <v-tabs
                                    v-model="tab"
                                    >
                                    <v-tabs-slider class="maximum_red-fondo"></v-tabs-slider>
                                    <v-tab key="usuarios" href="#usuarios" class="jet-texto">
                                        Administración de usuarios
                                    </v-tab>

                                    <!--<v-tab key="grupos" href="#grupos" class="jet-texto">
                                        Administración de grupos
                                    </v-tab>

                                    <v-tab key="asociar" href="#asociar" class="jet-texto">
                                        Asociar usuarios a grupos
                                    </v-tab>-->
                                </v-tabs>

                            
                                <template>
                                    <v-tabs-items v-model="tab">

                                        <v-tab-item id="usuarios" value="usuarios">
                                            <UsuariosInterno></UsuariosInterno>
                                        </v-tab-item>

                                        <v-tab-item id="grupos" value="grupos">
                                            <Grupos></Grupos>
                                        </v-tab-item>

                                        <v-tab-item id="asociar" value="asociar">
                                        <AsociarGrupos></AsociarGrupos>
                                        </v-tab-item>
                                </v-tabs-items>
                                </template>
                        
                            </v-container>

                        </v-card-text>

                    </v-card>  
                </v-flex>
            </v-layout>
        </div>
    </v-app>
</template>

<script>
import UsuariosInterno from '@/components/Administracion/Usuarios/UsuariosInterno.vue'
import Grupos from '@/components/usuarios/Grupos.vue'
import AsociarGrupos from '@/components/usuarios/AsociarGrupos.vue'
import Menu from '@/components/Menu.vue'
import Header from '@/components/Header.vue'

    export default {
    name: 'Administracion',

    components: {UsuariosInterno, Grupos, AsociarGrupos, Menu, Header },

    data() {
        return {
            tab: "",
            dataApps: []
        };
    },
}
    
</script>
<style>

.contenido {
    margin-top: 20px;
}
</style>
