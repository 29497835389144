<template>
    <div class="white-fondo">

        <v-btn class="maximum_red-fondo white-texto mt-5" v-on:click="agregarUsuario">
            <v-icon class="mr-5">
                mdi-account-plus
            </v-icon>
            Agregar nuevo usuario
        </v-btn>

       
        <v-card>
            <v-card-title>
                Usuarios
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="cabeceraUsuarios"
                    :items="dataUsuarios"
                    height="475"
                    :fixed-header=true
                    :footer-props="{'items-per-page-options':[50]}"
                >

                <template v-slot:item.sexo="{item}">
                    <v-icon v-if="item.sexo=='m'" class="maximum_red-texto">
                        mdi-face-man
                    </v-icon>
                    <v-icon v-else-if="item.sexo=='f'" class="maximum_red-texto">
                        mdi-face-woman
                    </v-icon> 
                </template>
               <template v-slot:item.activo="{item}">
                    <span>{{ formatoEstado(item.activo) }}</span>
               </template>
                <template v-slot:item.acciones="{item}">

                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-on:click="editarUsuario(item)" size="x-small" class="maximum_red-fondo white-texto" v-bind="attrs" v-on="on" >
                                <v-icon>
                                    mdi-account-edit
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Editar usuario</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                class="ml-2" color="#A3A3A3" v-on:click="abrirConfirmacionEliminar(item.id_usuario, item.nombre)" v-bind="attrs" v-on="on">
                                <v-icon>
                                    mdi-account-remove
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Eliminar usuario</span>
                    </v-tooltip>

                    <v-tooltip v-if="item.activo==1" top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn  class="jet_fondo ml-2" @click="modificarEstado(item.id_usuario, 0)" v-bind="attrs" v-on="on">
                                <v-icon>
                                    mdi-account-cancel
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Desactivar usuario</span>
                    </v-tooltip>
                   
                    <v-tooltip v-if="item.activo==0" top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn  class="jet_fondo ml-2" @click="modificarEstado(item.id_usuario, 1)" v-bind="attrs" v-on="on">
                                <v-icon>
                                    mdi-account-check
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Activar Usuario</span>
                    </v-tooltip>
                   
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="black-fondo ml-2" @click="abrirAdministrarApps(item.id_usuario, item.nombre)" v-bind="attrs" v-on="on">
                                <v-icon class="white-texto">
                                    mdi-television-guide
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Administrar modulos usuario</span>
                    </v-tooltip>
                </template>

                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog v-model="formUsuario" width="800" persistent>
            <v-form @submit.prevent="validar()">
                <v-card>
                    <v-card-title>
                        {{ textoTituloFormulario }}
                    </v-card-title>
                    <v-card-text>
                            <br>
                            <v-row>
                                <v-col>
                                    <h5>Nombre Usuario</h5>
                                    <v-text-field
                                    v-model="objUsuario.nombre"
                                    outlined
                                    dense
                                    value="objUsuario.nombre">
                                </v-text-field>
                                <div class = "red--text" v-if = "submited && !$v.objUsuario.nombre.required" > Este campo es obligatorio</div>
                                </v-col>
                                <v-col>
                                    <h5>Usuario AD</h5>
                                    <v-text-field
                                    v-model="objUsuario.correo"
                                    outlined
                                    dense
                                    value="objUsuario.correo">
                                </v-text-field>
                                <div class = "red--text" v-if = "submited && !$v.objUsuario.correo.required" > Este campo es obligatorio</div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <h5>Grupo de Venta</h5>
                                    <v-autocomplete v-model="objUsuario.grupo_venta"
                                    :items="gruposVenta"
                                    outlined
                                    dense
                                    item-text="gruposVenta"
                                ></v-autocomplete>
                                <div class = "red--text" v-if = "submited && !$v.objUsuario.grupo_venta.required" > Este campo es obligatorio</div>
                                </v-col>
                                <v-col>
                                    <h5>Geografía</h5>
                                    <v-autocomplete v-model="objUsuario.geografia"
                                    :items="geografias"
                                    outlined
                                    dense
                                    item-text="geografias"
                                ></v-autocomplete>
                                <div class = "red--text" v-if = "submited && !$v.objUsuario.geografia.required" > Este campo es obligatorio</div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <h5>Area</h5>
                                    <v-autocomplete v-model="objUsuario.area"
                                    :items="areas"
                                    outlined
                                    dense
                                    item-text="areas"
                                ></v-autocomplete>
                                <div class = "red--text" v-if = "submited && !$v.objUsuario.area.required" > Este campo es obligatorio</div>
                                </v-col>
                                <v-col>
                                    <h5>Unidad de Negocio</h5>
                                    <v-autocomplete v-model="objUsuario.unidad_negocio"
                                    :items="UnidadNegocios"
                                    outlined
                                    dense
                                    item-text="UnidadNegocios"
                                ></v-autocomplete>
                                <div class = "red--text" v-if = "submited && !$v.objUsuario.unidad_negocio.required" > Este campo es obligatorio</div>
                                </v-col>
                            </v-row>
                        <v-row>
                            <v-col>
                                <h5>Usuario SAP</h5>
                                <v-text-field
                                    v-model="objUsuario.sap"
                                    outlined
                                    dense
                                    >
                                </v-text-field>
                                <div class = "red--text" v-if = "submited && !$v.objUsuario.sap.required" > Este campo es obligatorio</div>
                            </v-col>
                            <v-col>
                                <h5>Sexo</h5>
                                <v-radio-group v-model="objUsuario.sexo">
                                    <v-row>
                                        <v-col>
                                            <v-radio 
                                                label="Masculino"
                                                value="m">
                                            </v-radio>
                                        </v-col>
                                        <v-col>
                                            <v-radio
                                                label="Femenino"
                                                value="f">
                                            </v-radio>
                                        </v-col>
                                    </v-row>
                                </v-radio-group>
                                <div class = "red--text" v-if = "submited && !$v.objUsuario.sexo.required" > Este campo es obligatorio</div>
                            </v-col>
                        </v-row>
                        
                    </v-card-text>
                    <v-card-actions>
                        <v-btn class="maximum_red-fondo white-texto" type="submit">
                            {{ textobtnFormulario }}
                        </v-btn>
                        <v-btn class="old_rose-fondo white-texto" v-on:click="formUsuario=false">
                            Cancelar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <v-dialog v-model="dialogApps" width="1000" persistent>
            <UsuariosApps :idUsuario="usuarioId" :nombreUsuario="usuarioNombre" @cerrarModulos="cerrarGestionModulos"></UsuariosApps>
        </v-dialog>
        <v-dialog v-model="dialogConfirmacionEliminar" width="500px" persistent>
            <v-card >
                <v-card-title class="justify-center">
                    <v-icon class="icon-vertical-align" x-large color="red">
                            mdi-alert
                        </v-icon>
                </v-card-title>
                <v-card-text class="text-center">
                    <br>
                    <h4>¿ Esta seguro que desea eliminar a {{ this.usuarioNombre }} ?</h4>
                </v-card-text>
                <v-card-actions class="text-center">
                    <v-flex class="px-5 pb-1">
                        <v-btn class="maximum_red-fondo white-texto" @click="borrarUsuario()">Eliminar</v-btn>
                    </v-flex>
                    <v-flex class="px-1 pb-1">
                        <v-btn class="ml-2 white-texto" color="#A3A3A3" @click="cerrarConfirmacionEliminar()">Cancelar</v-btn>
                    </v-flex> 
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbarApp">
            {{ snackAppTexto }}
        </v-snackbar>
    </div>
</template>


<script>
import axios from 'axios'
import {required} from 'vuelidate/lib/validators';
import UsuariosApps from '@/components/Administracion/Usuarios/UsuariosApps.vue';

export default{
    name:'UsuariosInterno', 
    components:{UsuariosApps},

    data() {
        return {
            usuarioId:0,
            usuarioNombre:'',
            dialogConfirmacionEliminar:false,
            dialogApps:false,
            submited:false,
            objUsuario: {
                id_usuario: 0,
                nombre: "",
                correo:"",
                sexo:"",
                sap:"",
                estado:1,
                grupo_venta:"",
                geografia:"",
                area:"",
                unidad_negocio:"",
            },
            gruposVenta:['Sin grupo','Planta Mármol','Showroom Concepcion','Venta Empresa','Showroom Viña del Mar','Canal Digital','Showroom Santiago','Showroom Puerto Varas','Outlet'],
            geografias:['Santiago','Concepción','Viña del mar','Puerto Varas','Norte'],
            areas:['Productos','Logistica','Administración','Ecommerce','Venta Showroom','Gerencia General','Informatica','Marketing',
            'Mantención','Venta Distribuidores','Contabilidad','Comercial','Venta Planta Mármol','Venta Empresa','Operaciones','Operaciones Huechuraba',
            'Diseño','Servicios PostVenta','Especificaciones Arquitectos','Especificaciones inmobiliarias'],
            UnidadNegocios:['Comercial','Informatica','Contabilidad','Mármol'],
            cabeceraUsuarios: [
                { text:'Nombre usuario', value:'nombre',},
                { text:'Usuario AD', value:'correo',},
                { text:'Sexo', value:'sexo'}, 
                { text:'Estado', value:'activo'}, 
                { text:'Usuario SAP', value:'sap'},
                { text:'Grupo de Venta', value:'grupo_venta'},
                { text:'Geografía', value:'geografia'},
                { text:'Area',value:'area'},
                { text:'Unidad de Negocio', value:'unidad_negocio'},
                { text:'Acciones', value:'acciones'}
            ],
            dataUsuarios:[],
            snackbarApp: false,
            snackAppTexto: "",
            formUsuario:false,
            textoTituloFormulario:'Ingresar nuevo usuario',
            textobtnFormulario:'Registrar usuario',
            botonDesactivar:'Desactivar'       
        }
    },
    
    created() {
        this.mostrarUsuarios()
    },

    methods: {
        mostrarUsuarios: function() {
            console.log("mostrar usuarios")
            axios.get(process.env.VUE_APP_API_USUARIOS_LISTAR).then(response => {
                this.dataUsuarios=response.data
                console.log(this.dataUsuarios);
            })
        }, 

        agregarUsuario:function() {
            this.objUsuario.id_usuario=0;
            this.objUsuario.nombre= "";
            this.objUsuario.correo="";
            this.objUsuario.sexo="";
            this.objUsuario.sap="";
            this.objUsuario.estado=1;
            this.objUsuario.grupo_venta="";
            this.objUsuario.geografia="";
            this.objUsuario.area="";
            this.objUsuario.unidad_negocio="";
            this.submited=false;

            this.textoTituloFormulario="Agregar nuevo usuario"
            this.textobtnFormulario="Registrar usuario"
            this.formUsuario=true
        },

        validar () {
            console.log("Validar");
            this.submited = true;

            if(this.$v.$invalid) {
                return false;
            }else{
                this.ejecutaFormulario();
            }
        },
        ejecutaFormulario:function() {
            console.log("Ejecuta formulario")
            console.log(this.objUsuario)
            let datos = {
                apikey:'ATK54',
                id_usuario: this.objUsuario.id_usuario,
                correo: this.objUsuario.correo,
                nombre: this.objUsuario.nombre,
                sexo: this.objUsuario.sexo,
                sap: this.objUsuario.sap,
                activo: this.objUsuario.estado,
                grupo_venta: this.objUsuario.grupo_venta,
                geografia: this.objUsuario.geografia,
                area: this.objUsuario.area,
                unidad_negocio: this.objUsuario.unidad_negocio
            }

            var ruta=''
    
            switch(this.textobtnFormulario)
                {
                    case 'Registrar usuario': ruta=process.env.VUE_APP_API_USUARIOS_CREAR 
                        this.snackAppTexto="Usuario agregado exitosamente"

                        console.log("Ruta invocada -> " + ruta)
                        axios.post(ruta,datos).then(response => {
                            console.log("respuesta axios")
                            console.log(response.data)
                            this.mostrarUsuarios()
                            this.formUsuario=false
                            this.snackbarApp=true
                        })
                    break;
                    case 'Actualizar usuario': ruta = ruta=process.env.VUE_APP_API_USUARIOS_ACTUALIZAR //'https://api.atika.cl/usuarios/actualizar' 
                        this.snackAppTexto="Usuario actualizado exitosamente"

                        console.log("Ruta invocada -> " + ruta)
                        axios.put(ruta,datos).then(response => {
                            console.log("respuesta axios")
                            console.log(response.data)
                            this.mostrarUsuarios()
                            this.formUsuario=false
                            this.snackbarApp=true
                        })
                }
            
        }, 
        abrirConfirmacionEliminar(id_usuario, nombre) {
            this.dialogConfirmacionEliminar=true;
            this.usuarioId=id_usuario;
            this.usuarioNombre=nombre;
        },
        cerrarConfirmacionEliminar() {
            this.dialogConfirmacionEliminar=false;
            this.usuarioId=0;
            this.usuarioNombre='';
        },
        borrarUsuario:function() {
            let ruta =process.env.VUE_APP_API_USUARIOS_ELIMINAR + "/" + this.usuarioId

            axios.get(ruta).then(response => {
                console.log("Respuesta axios")
                console.log(response.data)
                this.mostrarUsuarios()
                this.dialogConfirmacionEliminar=false;
                this.snackbarApp=true;
                this.snackAppTexto="Usuario eliminado exitosamente";
            })
        }, 

        editarUsuario:function(usuario) {
            
            this.objUsuario.id_usuario=usuario.id_usuario;
            this.objUsuario.nombre= usuario.nombre;
            this.objUsuario.correo=usuario.correo;
            this.objUsuario.sexo=usuario.sexo;
            this.objUsuario.sap=usuario.sap;
            this.objUsuario.estado=usuario.activo;
            this.objUsuario.grupo_venta=usuario.grupo_venta;
            this.objUsuario.geografia=usuario.geografia;
            this.objUsuario.area=usuario.area;
            this.objUsuario.unidad_negocio=usuario.unidad_negocio;
            
            console.log("editar usuario")
            this.textoTituloFormulario="Editar usuario"
            this.textobtnFormulario="Actualizar usuario"
            this.formUsuario=true
            this.submited=false;
        },

        formatoEstado(estado){
            if(estado==1)
                return "Activo"
            else
                return "Inactivo"
        },
        modificarEstado(id_usuario, estado){
            console.log("Modificar estado: " + estado);
            console.log("id usuario: " + id_usuario);
            let datos = {
                id_usuario: id_usuario,
                estado: estado
            }
            let ruta = process.env.VUE_APP_ACTUALIZAR_ESTADO_USUARIO
            console.log("Ruta invocada -> " + ruta);
            axios.put(ruta, datos).then(response => {
                let respuesta = response.data
                console.log(respuesta);
                this.mostrarUsuarios();
            })
        },
        abrirAdministrarApps(id_usuario, nombre){
            
            console.log("abrirAdministrarApps");
            this.dialogApps=true;
            this.usuarioId=id_usuario;
            this.usuarioNombre=nombre;
        },
        cerrarGestionModulos(){
            this.usuarioId=0;
            this.usuarioNombre='';
            this.dialogApps=false;
        }
    },
    validations: {
        objUsuario: {
            nombre: {required},
            correo: {required},
            sexo: {required},
            sap: {required},
            grupo_venta: {required},
            geografia: {required},
            area: {required},
            unidad_negocio: {required}
        }
    }
            
}
</script>

